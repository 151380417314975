body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'Lufga-Black';
	src: local('Lufga-Black'), url(./fonts/Lufga-Black.otf) format('otf'),
		url(./fonts/Lufga-Black.woff) format('woff'),
		url(./fonts/Lufga-Black.woff2) format('woff2');
}

@font-face {
	font-family: 'Lufga-Bold';
	src: local('Lufga-Bold'), url(./fonts/Lufga-Bold.otf) format('otf'),
		url(./fonts/Lufga-Bold.woff) format('woff'),
		url(./fonts/Lufga-Bold.woff2) format('woff2');
}

@font-face {
	font-family: 'Lufga-Medium';
	src: local('Lufga-Medium'), url(./fonts/Lufga-Medium.otf) format('otf'),
		url(./fonts/Lufga-Medium.woff) format('woff'),
		url(./fonts/Lufga-Medium.woff2) format('woff2');
}
