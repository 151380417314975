.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(238, 223, 223);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Full Desktop */

@media only screen and (max-width: 1920px) and (min-width: 200px) {
  .blink_me {
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  /* -- Global --*/

  .global {
    background-color: white;
    width: 100vw;
  }

  /* -- Navbar --*/

  .navbar {
    height: 100px;
  }
  .navbar-title {
    font-size: 60px;
    font-family: 'Lufga-Bold';
    color: black;
    line-height: 1em;
    margin-top: 55px;
    margin-left: 5px;
  }

  .navbar-small {
    font-size: 15px;
    font-family: 'Lufga-Medium';
    color: black;
    margin-top: 50px;
  }

  .asterisk {
    margin-top: 60px;
    margin-left: 20px;
  }

  /* -- Weekly Record -- */

  .weekly-record {
    font-size: 40px;
    font-family: 'Lufga-Medium';
    color: black;
    line-height: 1em;
    margin-top: 40px;
  }

  .weekly-overall {
    font-size: 26px;
    font-family: 'Lufga-Medium';
    color: black;
    line-height: 1em;
    margin-top: 4px;
  }

  .purple {
    color: #bd32ff;
  }

  .red {
    color: #ff2929;
  }

  .orange {
    color: #ff9d29;
  }

  .blue {
    color: #1068b9;
  }

  .all-time {
    font-size: 25px;
    font-family: 'Lufga-Medium';
    margin-left: 20px;
    padding-bottom: 50px;
  }

  .main-out-of-3 {
    font-size: 28px;
    font-family: 'Lufga-Medium';
    color: black;
    line-height: 0.8em;
    margin-top: 4px;
    margin-left: 10px;
  }

  .main-on-the-year {
    font-size: 15px;
    font-family: 'Lufga-Bold';
    color: black;
    line-height: 0.8em;
    margin-top: 7px;
    margin-left: 7px;
    text-align: right;
  }

  .main-status {
    font-size: 18px;
    font-family: 'Lufga-Medium';
  }

  .main-score {
    font-size: 30px;
    font-family: 'Lufga-Bold';
  }

  .main-tabs {
    font-family: 'Lufga-Bold';
  }

  .main-cover {
    font-size: 18px;
    font-family: 'Lufga-Medium';
  }

  .main-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .main-icon-center {
    position: relative;
  }

  /* -- Data Boxes --*/

  .data-box {
    /* box-shadow: 0 8px 16px rgba(100, 100, 100, 0.07),
			0 64px 128px rgba(0, 0, 0, 0.07);

		border-radius: 50px;
		background: white; */
    background: rgb(255, 255, 255);
  }

  /* -- Individual Records --*/

  .indiv-title {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 22px;
    background-color: black;
    width: 370px;
    color: white;
  }

  .indiv-name {
    font-family: 'Work Sans', sans-serif;
    font-weight: 800;
    font-size: 30px;
  }

  .indiv-disclaimer {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 10px;
    margin-left: 20px;
    margin-bottom: -10px;
    margin-top: -5px;
  }

  .indiv-record {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin-right: 10px;
  }

  .indiv-percent {
    margin-top: 10px;
  }

  /* -- Live Styling --*/

  .live-record {
    border-width: 10px;
    border-color: black;
    height: 600px;
    width: 900px;
    background: white;
    margin-top: 80px;
  }

  .navbar-links {
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    color: black;
  }

  .player-name {
    color: white;
  }

  .player-country {
    color: white;
  }

  /* -- Record Styling --*/

  .record-label {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 17px;
    background-color: black;
    color: white;
    width: 170px;
  }

  .record-data {
    font-family: 'Work Sans', sans-serif;
    font-weight: 800;
    font-size: 60px;
  }

  .record-win-rate {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 15px;
  }

  .records-bar {
    border-width: 10px;
    border-color: black;
    height: 600px;
    width: 520px;
    background: white;
    margin-top: 80px;
  }

  .records-content {
    margin-left: 25px;
    margin-top: 35px;
  }

  .records-bg {
    background-color: rgb(40, 129, 63);
    height: 100%;
    position: sticky;
    top: 0;
  }

  .score-box {
    border-width: 1px;
    border-radius: 10px;
    border-color: rgba(128, 128, 128, 0.568);
    width: 200px;
    height: 130px;
  }

  .score-status {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 13px;
  }

  .score-spread {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 10px;
  }

  .score-cover {
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    font-size: 10px;
  }

  .score-score {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
  }

  .score-at {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 13px;
  }

  .weekly-header {
    font-family: 'Work Sans', sans-serif;
    font-weight: 800;
    font-size: 30px;
    margin-top: 28px;
    margin-right: 30px;
  }

  /* -- Games --*/

  .games {
    padding-bottom: 0px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
